<template>
  <div class="wrapper">
    <div class="title">
      <svg-icon icon-class="point"></svg-icon>
      <span style="margin-left: 10px">{{moment(item.created_at).format('YYYY-MM-DD hh:mm:ss')}}</span>
    </div>
    <div class="content-info">
      <el-input v-model="item.content" rows="5" type="textarea"></el-input>
    </div>
    <div class="imgBox" v-if="item.img.length">
      <div class="content-img" v-for="(item, index) in item.img" :key="index">
        <el-image
          alt="图片"
          :src="item"
          :preview-src-list="[item]">
        </el-image>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  created(){
    console.log(this.item)
  },
  props:{
    item:Object
  },
  data(){
    return {
      moment,
    }
  },
  filters:{
    filterDate(val) {
      return val.substring(0,10)
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 650px;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  padding: 10px 20px;
  box-sizing: border-box;
  .title{
    margin-left: -15px;
    margin-bottom: 10px;
  }
  .imgBox {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    .content-img {
      width: 100px;
      height: 100px;
      overflow: hidden;
      margin-right: 12px;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
::v-deep .el-textarea__inner {
  font-size: 15px;
  font-family: PingFang SC;
  color: black;
  line-height: 1.2;
}
</style>
