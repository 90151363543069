<template>
  <div class="track" v-if="trackList.length">
    <h1>追踪记录</h1>
    <div class="cardboxs">
      <div class="card-content" v-for="(item,index) in trackList" :key="index">
        <info-card :item="item"></info-card>
      </div>
    </div>
  </div>
</template>

<script>
import InfoCard from "./InfoCard.vue";
export default {
  props: {
    trackList: {
      type: Array,
    },
  },
  components: { InfoCard },
};
</script>

<style lang="scss" scoped>
.track {
  h1 {
    font-size: 25px;
    margin: 40px 0;
  }
  .cardboxs {
    display: flex;
    flex-direction: column;
    .card-content{
      border-bottom: 1px solid  #E4E4E4;
      padding: 20px 50px;
    }
  }
}
</style>
