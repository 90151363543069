<template>
  <div class="noaccept" v-if="reportInfo">
    <h1>上报信息</h1>
    <span>报事时间:{{moment(reportInfo.created_at).format('YYYY-MM-DD hh:mm:ss')}}</span>
    <span>报事人:{{ reportInfo.name}}</span>
    <span>所在监督岗:{{ reportInfo.sentry_name}}</span>
    <div style="margin-bottom: 50px;border-bottom:1px solid #E4E4E4"></div>
    <span>报事地理位置:{{ reportInfo.address }}</span>
    <span>报事原因:{{ reportInfo.content }}</span>
    <div style="border:1px solid #E4E4E4"></div>
    <h1 style="margin-top:20px">上传图片</h1>
    <div class="images" v-if = reportInfo.img.length>
      <div class="image" v-for="(item, index) in reportInfo.img" :key="index">
        <img :src="item" alt="图片" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      moment,
      reportInfo: {},
    };
  },
  watch: {
    report: {
      handler(val) {
        this.reportInfo = val;
      },
      deep: true,
      immediate: true,
    },
  },
  props: {
    report:Object
  },
};
</script>

<style lang="scss" scoped>
.noaccept {
  margin-top: 30px;
  display: flex;
  border-bottom: 1px solid #E4E4E4;
  flex-direction: column;
  h1{
       margin: 40px 0;
      font-size: 20px;
  }
  span {
    line-height: 40px;
  }
  .images {
    display: flex;
    flex-wrap: wrap;
    .image {
      width: 200px;
      height: 200px;
      background: #999999;
      border: 1px solid #707070;
      overflow: hidden;
      margin: 10px 23px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
