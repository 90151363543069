<template>
  <right-content>
    <div class="status">
      <div class="status-content">
        <el-steps :space="200" :active="status" finish-status="success">
          <el-step title="未接受"></el-step>
          <el-step title="进行中"></el-step>
          <el-step title="已处理"></el-step>
        </el-steps>
      </div>
    </div>
    <div class="content">
      <no-accept :report="reportInfo" v-if="status > 0"></no-accept>
      <process :trackList="trackList" v-if="status > 1"></process>
      <div class="bottom" v-if="status>2">
        <div class="updateRow">
          <span>发现隐患</span>
          <el-input v-model="reportInfo.hidden_danger" disabled></el-input>
        </div>
        <div class="updateRow">
          <span>排查隐患</span>
          <el-input v-model="reportInfo.check_danger" disabled></el-input>
        </div>
        <!-- <div class="updateRow">
          <span>发现重大隐患</span>
          <el-input v-model="reportInfo.serious_hidden_danger" disabled></el-input>
        </div>
        <div class="updateRow">
          <span>排查重大隐患</span>
          <el-input v-model="reportInfo.check_serious_hidden_danger" disabled></el-input>
        </div> -->
        <div class="updateRow">
          <span style="font-size:10px;">减少经济损失(单位:万元)</span>
          <el-input v-model="reportInfo.reduce_loss" disabled></el-input>
        </div>
      </div>
    </div>
  </right-content>
</template>

<script>
import RightContent from "../commonViews/RightContent.vue";
import noAccept from "./components/noAccept.vue";
import Process from "./components/process.vue";
import { reportInfo} from '@/api'
export default {
  name: "list-detail",
  components: {
    Process,
    noAccept,
    RightContent,
  },
  async created() {
    const id = this.$route.query.id;
    const name = this.$route.query.name
    if (id) {
      const res = await reportInfo({
        report_id:id
      });
      if(res.status === 200) {
        this.reportInfo = res.data.data;
        this.reportInfo.name = name;
        this.trackList = this.reportInfo.disposes;
      }
    }
  },
  computed:{
    status(){
      if(this.reportInfo.type === 2) return 1;
      if(this.reportInfo.type === 1) return 2;
      if(this.reportInfo.type === 3) return 3;
    }
  },
  data() {
    return {
      reportInfo: "",
      trackList: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  justify-content: center;
  align-items: center;
  .status-content {
    width: 600px;
  }
}
.bottom {
  height: 200px;
  width: 100%;
  padding: 20px 50px;
  box-sizing: border-box;
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
  .updateRow{
    display: flex;
    flex: 1;
    height: 40px;
    border-radius: 5px;
    margin-right: 50px;
    span{
      width: 200px;
       background-color: #E4E4E4;
       margin-right: 20px;
       line-height: 40px;
       text-align: center;
        border-radius: 5px;
    }
  }
}

::v-deep .el-step__title {
  font-size: 12px;
  font-weight: bold;
  margin-left: -5px;
}
</style>
